
  import { mapState, mapMutations, mapActions } from 'vuex';
  import moment from 'moment';
  import isMobile from '@/mixins/is-mobile';
  import Header from '../components/common/Header.vue';
  import Logos from '~/assets/icons/icons.json';
  import CustomCart from '~/components/common/CustomCart.vue';
  import LocationPopUp from '~/components/globals/LocationPopUp.vue';
  import SiteBanner from '@pleinair/bigcolony-vue/components/storyblok/SiteBanner.vue';

  export default {
    name: 'CommonLayoutContainer',
    components: {
      Header,
      CustomCart,
      LocationPopUp,
      SiteBanner,
    },
    props: {
      webview: Boolean,
      footerMenu: Object,
      locationIcon: Object,
      chevron: Object,
      mainNavConfig: Object,
      mobileMenuConfig: Object,
    },
    mixins: [isMobile],
    data() {
      return {
        logos: Logos,
      };
    },
    computed: {
      ...mapState([
        'location',
        'loading',
        'errors',
        'token',
        'user',
        'cart',
        'showNav',
        'globals',
      ]),
      ...mapState({
        rewards: (state) => state.members.rewards,
      }),
      pagePath() {
        return this.$route.path.replace(/\//g, '_').replace(/_$/, '');
      },
      hasErrors() {
        if (this.errors) {
          if (Array.isArray(this.errors) && this.errors?.length > 0) {
            return true;
          } else if (typeof this.errors === 'string') {
            return true;
          } else if (typeof this.errors === 'object' && this.errors.message) {
            return true;
          }
        }
        return false;
      },
      webViewClass() {
        const pagePath = this.pagePath ? `page_${this.pagePath}` : '';
        return `${this.webview ? 'app' : 'web'}-layout ${pagePath}`;
      },
      config() {
        return {
          headerInfo: {
            title: 'Your Bag',
            description: `Review your ${this.cart?.handoff || ''} order`,
            leftAction: {
              show: false,
              text: '',
              iconName: 'arrow-left-icon',
              iconClasses: '',
              callback: () => {
                this.hideCart();
              },
            },
            rightAction: {
              show: true,
              hasOrderModeTooltip: true,
              tooltipAction: {
                text: '',
                title: '',
                message: '',
                buttonText: '',
                icon: 'close-icon',
              },
              callback: () => {
                this.hideCart();
              },
            },
          },
          cartTitle: {
            title: 'Your Order',
            leftAction: {
              show: false,
              iconName: 'order-icon',
              iconClasses: '',
            },
            rightAction: {
              show: true,
              text: 'Clear Bag',
              iconName: 'trash-icon',
              iconClasses: '',
              callback: async () => {
                this.setLoading(true)
                this.cart.products.map(async p => {
                  await this.removeProduct(p)
                })
                this.hideCart()
                this.$router.push(`/menu/${this.location.menu_id}`)
                this.setLoading(false)
              },
            },
          },
          cartUpsells: {
            title: 'Complete Your Order',
            leftAction: {
              show: false,
            },
            rightAction: {
              show: false,
            },
          },
          locationCardInfo: {
            separator: '•',
            showServicesOnCard: false,
            showServicesOnOperation: false,
            locationPageAction: '',
            locationPageIcon: '',
          },
          handoffInfo: {
            title: 'Pickup Time'
          },
          totals: {
            placement: 'page',
          },
        };
      },
    },
    methods: {
      ...mapMutations([
        'setToken',
        'setUser',
        'setCart',
        'setLocation',
        'setMenu',
        'setShowCart',
        'setErrors',
        'setLoading',
        'setGlobals'
      ]),
      ...mapActions([
        'getUser',
        'getCart',
        'abandonCart',
        'reloadLocation',
        'transferCart',
        'sendRequest',
        'changeLocation',
        'nuxtServerInit'
      ]),
      ...mapActions({
        getRewards: 'members/getRewards',
        removeProduct: 'product/removeProduct',
      }),
      hideCart() {
        document.body.style.overflow = 'auto';
        this.setShowCart(false);
      },
      async clearCart() {
        if (this.cart) {
          const today = new Date();
          const clearCartAt = new Date(this.cart.reset);
          if (moment(clearCartAt).isBefore(moment(today))) {
            if (this.$route.fullPath.includes('/order/checkout')) {
              this.$router.push('/order');
              setTimeout(
                () =>
                  this.setErrors(
                    'Your basket timed out, please recreate your order'
                  ),
                '1000'
              );
            }
            this.$utils.log('Basket cleared');
            await this.abandonCart();
          }
        }
      },
      async updateToken() {
        this.$utils.log(moment(this.token.expires).format('MM DD YYYY h:mm a'));
        if (moment().isAfter(moment(this.token.expires))) {
          const tokenConfig = {
            method: 'put',
            url: 'members/auth',
          };

          const token = await this.sendRequest(tokenConfig);
          if (token.status === 'ok') {
            if (token.expires === null) {
              const expirationTime = 10;
              const expires = moment().add(expirationTime, 'minutes');
              token.expires = moment(expires).valueOf();
            }
            this.$utils.log('** Token Refreshed!');
            await this.setToken(token.data);
            await this.getRewards();

            return true;
          }

          this.$utils.log('** Refresh Token Did not work.');
          this.$utils.log('** Force Sign-Out!');

          this.setUser(null);
          this.setCart(null);
          this.setMenu(null);
          this.setToken(null);
          this.setLocation(null);
          this.setErrors({
            message: `For your security you've been signed out. Please sign in again to continue.`,
            actions: [
              {
                type: 'internal',
                to: '/account/signin',
                title: 'Sign In',
                class: 'primary',
              },
              {
                type: 'button',
                callback: () => this.$router.push('/'),
                title: 'Dismiss',
                class: 'black outline',
              },
            ],
          });

          return true;
        }

        return false;
      },
    },
    async beforeMount() {
      console.log('test globals: ', this.globals)
      if (this.$route && this.$route.query && this.$route.query.store) {
        if (this.$route.query.store === 'clear') {
          this.setLocation(null);
          this.setMenu(null);
          await this.abandonCart();
        }
      }

      if (this.token && !this.user) {
        await this.getUser();
      }
      if (this.cart) {
        await this.getCart();
      }

      // Do not reload the location data if it is a menu page
      const isMenu = this.$route.path.includes('/menu')
      if (isMenu) {
        return
      }

      // Reload the location data - mainly the open/closed status
      if (this.location || this.cart) {
        const menuId =
          this.cart && this.cart.menu_id
            ? this.cart.menu_id
            : this.location.menu_id;
        await this.reloadLocation(menuId);

        // Transfer cart if the menus are still different for some reason
        if (
          this.location?.services?.transfercart &&
          this.cart?.menu_id !== this.location?.menu_id
        ) {
          this.transferCart(menuId);
        } else {
          await this.abandonCart();
        }
      }
    },
    async mounted() {
      if (this.token) {
        const tokenExpired = await this.updateToken();
        if (!tokenExpired) {
          await this.getRewards();
        }
      }
    },
    watch: {
      async $route() {
        this.clearCart();
        if (this.token) {
          await this.updateToken();
        }
      },
      showNav(show) {
        if (show && this.isMobile) {
          document.body.classList.add('prevent-scroll');
        } else {
          document.body.classList.remove('prevent-scroll');
        }
      },
    },
  };
